import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Heading } from 'components/Headings/Heading';
import { heroV4 } from './HeroV4.module.css';

const heroImg = {
	1: (
		<StaticImage
			src='../../../../assets/images/denture-repair/dentures-broken-in-half.jpg'
			alt='Denture repair lab in your city'
			width={800}
			height={559}
		/>
	),
	2: (
		<StaticImage
			src='../../../../assets/images/denture-repair/dental-technicican-repairing-dentures.jpg'
			alt='Dental lab nearby repairing dentures same-day'
			width={800}
			height={559}
		/>
	),
	3: (
		<StaticImage
			src='../../../../assets/images/denture-repair/partial-dentures-types.jpg'
			alt='fixing broken partial dentures'
			width={800}
			height={559}
		/>
	),
	4: (
		<StaticImage
			src='../../../../assets/images/denture-repair/denture-repair-price.jpg'
			alt='How much for denture repair near me?'
			width={800}
			height={559}
		/>
	),
	5: (
		<StaticImage
			src='../../../../assets/images/denture-repair/denture-repair-reline-rebase.jpg'
			alt='Walk-in local denture repair services '
			width={800}
			height={559}
		/>
	),
	6: (
		<StaticImage
			src='../../../../assets/images/denture-repair/denture-soaking-in-water.jpg'
			alt='Soaking dentures in water increases their durability'
			width={800}
			height={559}
		/>
	),
	7: (
		<StaticImage
			src='../../../../assets/images/denture-repair/people-wearing-dentures.png'
			alt='Patients taking care of dentures'
			width={800}
			height={559}
		/>
	),
};

export const HeroV4 = ({ title, description, imgNumber }) => {
	const config = {
		id: '',
		type: 'h2',
		align_desktop: 'center',
		align_mobile: 'center',
		title,
	};

	return (
		<div className={heroV4}>
			{heroImg[imgNumber]}
			<Heading blok={config} classes='mt-2 mb-1 mb-1--mobile mt-1--mobile' />
			<p className='txt-center c-black mt-1 mb-2 mb-1--mobile mt-1--mobile'>{description}</p>
		</div>
	);
};